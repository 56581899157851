// './base/jquery.touchSwipe.min.js';//基本
import scrollTop from './base/ap-scroll-top.js';//基本

/*************************************/

import sidebar from'./sidebar-nav.js';//選單
import hamburger from'./sidebar-nav-hamburger.js';//漢堡層動畫 mmnav時關閉
// mmenu
// import sidebarmmenu from'./sidebar-nav_mmMenu.js';//手機選單 O
// import mmenu from'./pluging/mmenu/mmenu.js';//手機選單 O
// import mmenupolyfills from'./pluging/mmenu/mmenu.polyfills.js';//手機選單 IE O
// import mmenummenucustomized from './pluging/mmenu/mmenucustomized.js';//手機選單 O

// 手機板選單
// import mobileFullSubNav from'./sidebar-nav-mobileFullSubNav.js';//滿版子選單左右欄分類
// import mobileFullSubNav from'./sidebar-nav-mobileSubNavDown.js';//滿版子選單與次選單下拉

// 電腦板選單
import sidebarfullImg from'./sidebar-nav--fullImg.js';// 電腦版滿版產品右邊滑過選項會換圖片
import nav_subdropdown from'./sidebar-nav_subdropdown.js';// 下滑子選單 次子選單收合
//
/*************************************/
//wow
// import wow from './pluging/wow.js';


//動畫用
// import wowCus from './aos.js';//無法包近來
// 動畫基本規則 (data-aos="fade-down" data-aos-delay="500" data-aos-duration="2000")
AOS.init({disable: 'mobile'});
/*************************************/

// parallaxElement 物件上下滑動
// import parallaxElement from'./pluging/parallaxElement/SimpleScrollingParallax.js';//物件視差滾動(非背景圖移動)


// import carouselfull from'./carousel-full.js';//輪播
import carouselSwipe from'./carousel-swipe.js';//輪播觸控

/*************************************/

// import colorboxmin from'./pluging/colorbox-master/jquery.colorbox-min.js';//圖片放大
// import colorbox from'./pluging/colorbox-master/colorbox.js';//圖片放大
/*************************************/

import zoomImg from'./pluging/zoom-master/jquery.zoom.js';// 放大鏡功能
import zoomSet from'./pluging/zoom-master/zoomset.js';// 放大鏡功能


// import easyzoomImg from'./pluging/easyzoom/dist/easyzoom.js';// 放大鏡功能 / 要兩種不同尺寸圖片才有作用
// import easyzoomSet from'./pluging/easyzoom/dist/easyzoom_copy.js';// 放大鏡功能

import magnifikImg from'./pluging/image-zoom-magnifik/src/magnifik.js';// 放大鏡功能
import magnifikSet from'./pluging/image-zoom-magnifik/src/magnifikset.js';// 放大鏡功能

/*************************************/

// './products-top.js';//上排產品選單
// './panel-collapse.js';//footer手機板選單收合
// './navActive.js';//nav heightline

/*************************************/
// panel 手機板產品選單開啟

// import panel from'./panel-easy.js';

// 手機板產品選單開啟
import mobileList from'./products-mobileList.js';
/*************************************/

//--卷軸樣式 無法包進來
//-- './pluging/scrollbar-plugin-master/jquery.mCustomScrollbar.concat.min.js';

/*************************************/
// Top tab RWD
// import tabcollapse from'./pluging/tabcollapse/bootstrap-tabcollapse.js';
// import tabcollapsecus from'./pluging/tabcollapse/tabcollapse-cus.js';



/*************************************/

//--幻燈片 slick
// import slick from'./pluging/slick/slick.js';//選單
// import slickCustom from'./pluging/slick/products-slick';//首頁
// import slickCustom from'./pluging/slick/slick__twin';//選單
// './pluging/slick/products-slick.js';


/*************************************/

//幻燈片 photoswipe //無法匯出
// import photoswipe from './pluging/photoswipe/photoswipe.min.js';
// import photoswipe2 from './pluging/photoswipe/photoswipe-ui-default.js';
// import photoswipe3 from './pluging/photoswipe/photoswipe-main4.js';

/*************************************/

//--幻燈片 swiper
// './pluging/swiper/swiper.js';//無法匯出
// import swiper from'./pluging/swiper/swiper-custom.js';

import swiperMasterProducts from './pluging/swiper-master/swiper-master-products.js';
import swiperMasterTwin from './pluging/swiper-master/swiper-master-twin.js';

/*************************************/


//瀑布流 masonry//無法匯出
// './pluging/masonry/imagesloaded.pkgd.min.js';
// './pluging/masonry/masonry.pkgd.js';
// './pluging/masonry/masonry.js';

// import masonry from'./pluging/masonry/masonry-easy.js';//瀑布流

/*************************************/
//lazyload 延遲讀圖片
import lazyloadFallback from './pluging/layzloading/noscriptFallback.js';
import lazyloadCheck from './pluging/layzloading/supportCheck.js';
import lazyloadmain from './pluging/layzloading/main.js';

import lazyloadbg from './pluging/layzloading/layzbg.js';
// 延遲css bg
import lazyloadbgcss from './pluging/layzloading/layzbg-cssbg.js';
// import lazyloadbg from './pluging/layzloading/layzbg2.js'; /不知道好不好用

/*************************************/

// 機械標明
// import showPopModal from './showPopModal.js';


/*************************************/


// 產品點擊下滑
// import autoScroll from './autoScroll.js';


/*************************************/

//倒數計步
// import count from './count.js';
/*************************************/
//搜尋彈窗

import modal from './modal.js';

/*************************************/
// 影片YT區
// YT彈窗
import modalYoutube from './modalYoutube.js';
// YT按鈕切換
// import clickYoutube from './clickYoutube.js';
