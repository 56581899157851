// // 大小雙 swiper
// var swiper = new Swiper(".mySwiper", {
//   // loop: true,
//   spaceBetween: 10,
//   slidesPerView: 1,
//   freeMode: true,
//   watchSlidesProgress: true,
//   watchSlidesVisibility: true,
//
// });
// var swiper2 = new Swiper(".mySwiper2", {
//   // loop: true,
//   spaceBetween: 10,
//   slidesPerView: 1,
//
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   thumbs: {
//     swiper: swiper,
//   },
// });


$(document).ready(function() {
    var quoteSwiper = new Swiper('.mySwiper',{
    effect: 'fade',
  });
  //   var textSwiper = new Swiper('.mySwiper3',{
  //   effect: 'fade',
  // });
    var imageSwiper = new Swiper('.mySwiper2', {
       navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 3000,
        },
    });
    quoteSwiper.controller.control = imageSwiper;
    imageSwiper.controller.control = quoteSwiper;

  });
